import * as React from 'react'
import { Link } from 'gatsby'
const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: var(textNormal);
padding-bottom: 2.5em;
line-height: 1.5em;`
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

class Agb extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container
        style={{
          color: 'var(--textNormal) !important',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}>

        <Element id={'header'}/>
      <h1   style={{
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}>Allgemeine Geschäftsbedingungen</h1>
        <br/>
        <br/>
<h2>1. Allgemeines</h2>
        <P>1.1 Für sämtliche Leistungen, welche die 2inSeven GmbH gegenüber Nutzern bzw. Vertragspartnern erbringt, gelten, ausschließlich
          die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB). Der Geltung abweichender Allgemeiner Geschäftsbedingungen des Nutzers wird widersprochen,
          es sei denn, die 2inSeven GmbH stimmt ihrer Geltung vor Vertragsschluss ausdrücklich schriftlich zu.</P>
        <br/>
        <P>1.2 Das Angebot richtet sich sowohl an Verbraucher im Sinne von § 13 des Bürgerlichen Gesetzbuches (BGB), als auch an Unternehmer im Sinne von § 14 BGB.</P>
        <P>1.3 Die Büroarbeitsplätze sind ausgestattet mit: Tisch, Stuhl, Strom, Internetanbindung über WLAN und gemeinschaftlicher Nutzung des Multifunktionsterminals.</P>
      <P>1.4 Die Arbeitsplätze dürfen durch den Nutzer ausschließlich für den im Antrag angegebenen Zweck benutzt werden. Eine Änderung des Zwecks bedarf der ausdrücklichen schriftlichen Zustimmung der 2inSeven GmbH.
        Ein Verstoß gegen diese Bestimmung berechtigt die 2inSeven GmbH zur fristlosen Kündigung und Schadenersatzanspruch.</P>


        <h2>2. Leistungsbeschreibung</h2>
        <P>Gegenstand der Angebote und Dienstleistungen der 2inSeven GmbH ist die Bereitstellung von Büroarbeitsplätzen, eigenem Hygienebereich (Dusche, Waschbecken, WC)
          einschließlich Internetzugang sowie die Bereitstellung eines Besprechungs- und Konferenzraumes.
          Ein regelmäßiger Reinigungs- und Desinfektionsservice ist inklusive. Ein hauseigener Frühstücksservice steht nach Voranmeldung zur Verfügung.</P>

        <h2>3. Buchungspakete</h2>
        <P>3.1 Die aktuell angebotenen Buchungspakete können dem Preis- und Leistungsverzeichnis entnommen werden.</P>
        <P>3.2 Nutzer, welche lediglich das Meeting- und Konferenzraumangebot nutzen, sind „externe Nutzer“.</P>
        <br/>
        <h2>4. Zugangsbedingungen</h2>
        <P>4.1 Die allgemeinen Öffnungszeiten der Rezeption sind Mo. – Fr. von 6:00 bis 18:00 Uhr.</P>
<P>4.2 Der Zugang zum Coworking Space richtet sich je nach abgeschlossener Vertragsart im Sinne von Ziffer 1 des Preis- Leistungsverzeichnisses.</P>
     <br/>
     <h2>5. Vertragsschluss</h2>
        <P>5.1 Der Vertragsschluss erfolgt durch Unterschrift des Nutzers und der 2inSeven GmbH auf dem Nutzungs- und Überlassungsvertrag.
          Der Vertragsbeginn ist nicht an den Beginn eines Monats gebunden.</P>
        <P>5.2 Die Anmeldung einer juristischen Person darf nur durch einen Vertretungsberechtigten vorgenommen werden.</P>
<P>5.3 Mit der Anmeldung sichert der Nutzer zu, dass die angegebenen Daten vollständig und
  wahrheitsgemäß sind. Der Nutzer verpflichtet sich,
  die Änderung seiner persönlichen Daten unverzüglich anzuzeigen.</P>
        <P>5.4 Durch den Vertragsabschluss akzeptiert der Nutzer die Allgemeinen Geschäftsbedingungen der 2inSeven GmbH.</P>
      <P>5.5 Es besteht kein Anspruch des Nutzers auf Abschluss eines Vertrages. Es steht der 2inSeven GmbH frei, jedes Angebot eines Nutzers zum Abschluss eines Vertrages ohne Angabe von Gründen abzulehnen.</P>

        <h2>6. Preise</h2>
        <P>6.1 Alle Preise der 2inSeven GmbH sind Nettopreise zuzüglich der jeweils gültigen gesetzlichen Umsatzsteuer.</P>
      <P>6.2 Die aktuellen Preise können dem Preis- und Leistungsverzeichnis entnommen werden.</P>
      <P>6.3 Der 2inSeven GmbH steht es frei, die Preise für interne Nutzer mit einer Frist von zwei Monaten zu ändern. Dem Nutzer steht es frei den Vertrag innerhalb von 14 Tagen nach Erhalt der Preisänderungen zu kündigen. Nach Kündigung endet der Vertrag mit dem letzten Tag vor Beginn der Gültigkeit des neuen Preises.</P>
      <h2>7. Kaution</h2>
        <P>7.1 Die Höhe der Kaution richtet sich nach der Größe des office-Paketes. Bei Buchung des Wochenpaketes beträgt die Kaution pro office 500 EUR, bei Buchung des Monatspaketes beträgt die Kaution pro office 1500,00 EUR.</P>
      <P>7.2 Die Kaution ist innerhalb von drei Arbeitstagen nach Vertragsabschluss zahlbar. Bis zum Zahlungseingang erhält der Kunde keinen Schlüssel und ist lediglich berechtigt die Räumlichkeiten innerhalb der Öffnungszeiten der Rezeption zu nutzen. Zahlt der Nutzer nach Ablauf der drei Arbeitstage die Kaution nicht, behält sich die 2inSeven GmbH  den fristlosen Rücktritt vom Vertrag vor.</P>
      <br/>

        <h2>8. Rechnungsstellung</h2>
        <P>8.1 Die Abrechnung für die Leistungspakete gem. des Preis- und Leistungsverzeichnisses und der Zusatzleistungen erfolgt innerhalb der ersten fünf Arbeitstage des jeweiligen Nutzungsmonats. Die erste Rechnung eines neuen Vertragsverhältnisses wird innerhalb der ersten fünf Arbeitstage nach Vertragsschluss erstellt. Die Abrechnung für die nutzungsabhängigen Leistungen für einen
          Monat wird innerhalb der ersten fünf Arbeitstage des Folgemonats erstellt.</P>

        <P>8.2 Die Rechnungszustellung erfolgt per E-Mail an die auf dem Nutzungs- und Überlassungsvertrag hinterlegte E-Mail-Adresse.</P>
      <P>8.3 Sofern das Vertragsverhältnis nicht zum Monatsersten beginnt, werden die Basispreise und Zusatzleistungen für den ersten Monat anteilig im Verhältnis der verbleibenden Tage des Monats zu der Anzahl der Tage des gesamten Monats berechnet.</P>
<P>8.4 Sofern das Vertragsverhältnis nicht zum Monatsersten beginnt und der Nutzer eine Teilzeitoption nutzt, berechnen sich die im Paket enthaltenen Tage aus der Multiplikation der Nutzungstage für einen vollen Monat mit dem Verhältnis der verbleibenden Tage des Monats zu der Anzahl der Tage des gesamten Monats. Es wird aufgerundet.</P>
     <P>8.5 Die Abrechnung des Meeting- und Konferenzraumes durch interne Nutzer erfolgt unmittelbar nach der Nutzung. Buchungen, die nicht spätestens 24h vor dem Termin abgesagt wurden, werden voll berechnet.</P>
     <br/>
        <h2>9. Zahlungsmodalitäten</h2>
        <P>9.1 Die Rechnung per Überweisung ist jeweils 7 Tage nach Rechnungsstellung ohne Abzug von Skonto fällig.</P>
<P>9.2 Der Nutzer kann die Zahlung auch per Kreditkarte, ec-Karte und PayPal  vornehmen.</P>
     <P>9.3 Buchungen des Meeting- und/oder Konferenzraums durch externe Nutzer sind vorab zahlbar. Ist bis zum Veranstaltungstag keine Zahlung eingegangen, ist die 2inSeven GmbH berechtigt, den Zugang zu verweigern. Ein Anspruch auf Schadens- oder Aufwendungsersatz gegenüber der 2inSeven GmbH entsteht nicht.</P>
      <br/>
      <h2>10. Datenschutz</h2>
        <P>10.1 Die 2inSeven GmbH wird die Vorschriften über den Datenschutz nach dem Bundesdatenschutzgesetz und den weiteren gesetzlichen Vorschriften zum Datenschutz beachten.</P>
     <P>10.2 Der Nutzer erklärt sein Einverständnis damit, dass seine für die Vertragsdurchführung notwendigen persönlichen Daten auf Datenträgern gespeichert werden. Sämtliche Daten werden von der 2inSeven GmbH vertraulich behandelt.</P>
      <P>10.3 Sollte ein Nutzer Kenntnis über vertrauliche Informationen oder Geschäftsgeheimnisse anderer Nutzer erlangen, ist der Nutzer zum Stillschweigen verpflichtet, sofern dies nicht dem Gesetz entgegensteht. Ein Verstoß gegen diese Bestimmung berechtigt die 2inSeven GmbH zur fristlosen Kündigung.</P>

      <h2>11. Kündigungen</h2>
        <P>11.1 Beide Vertragsparteien können den Nutzungs- und Überlassungsvertrag unter Einhaltung einer Kündigungsfrist von einer Woche kündigen.</P>
      <P>11.2 Die 2inSeven GmbH kann das Vertragsverhältnis ohne Einhaltung einer Kündigungsfrist mit sofortiger Wirkung kündigen, wenn ein Grund zur außerordentlichen Kündigung vorliegt. Dieser liegt auch vor, wenn der Nutzer für zwei aufeinander folgende Termine mit der Entrichtung des Entgelts oder eines nicht unerheblichen Teils des Entgelts in Verzug ist. Weitere gesetzliche Kündigungs- und Rücktrittsrechte der
        2inSeven GmbH und des Nutzers bleiben hiervon unberührt.</P>
        <P>11.3 Die 2inSeven GmbH kann das Vertragsverhältnis innerhalb der ersten Woche des Vertragsverhältnisses ohne Einhaltung einer Kündigungsfrist und ohne Angaben von Gründen mit sofortiger Wirkung kündigen.</P>
      <P>11.4 Alle Kündigungen bedürfen der Schriftform.</P>
        <br/>
        <h2>12. Vertragsdurchführung</h2>
        <P>12.1 Die Untervermietung an Dritte bedarf der vorherigen schriftlichen Zustimmung durch die 2inSeven GmbH. Das Übernachten und Wohnen im office ist ausdrücklich untersagt.</P>
      <P>12.2 Der Nutzer unternimmt keine Versuche unberechtigten Zugriffs auf die Infrastruktur z.B. durch Hacking.</P>
      <P>12.3 Der Nutzer bestätigt, dass er die Dienste und Infrastruktur der 2inSeven GmbH für keine der im Folgenden aufgezählten Tätigkeiten nutzen wird:</P>
      <P>a. Jegliche rechtswidrige Tätigkeit, insbesondere:
        Tätigkeiten, welche dem Persönlichkeitsrecht und dem Schutz der Privatsphäre von privaten und juristischen Personen entgegenstehen, zum Beispiel Diffamierung, Missbrauch, Belästigung oder Stalking;
        Verbreitung von ungesetzlichen Materialien wie zum Beispiel sittenwidrigem oder beleidigendem Material innerhalb oder über die der 2inSeven GmbH bereitgestellte Infrastruktur;
        Verbreitung oder Bereitstellung von Daten, insbesondere Bildern, Fotografien, Filmen oder Software welche den Gesetzen zum Schutz von geistigem Eigentum unterliegen, es sei denn der Nutzer ist Rechte-Inhaber oder besitzt die Berechtigung zur Verbreitung;
        Verbreitung von Schadsoftware wie Viren, Trojaner, Würmer oder Bots; illegaler Download von urheberrechtlich geschützten Daten;</P>
      <P>b. Tätigkeiten, welche der Integrität der 2inSeven GmbH und den Nutzern schaden können, insbesondere:
        unrechtmäßige Beschaffung von persönlichen Daten innerhalb und außerhalb der 2inSeven GmbH;
        Nutzung im Zusammenhang mit Kettenbriefen, Spam-E- Mail oder sonstige Art von unerwünschten Nachrichten oder Werbung;
        Nutzung im Zusammenhang mit Gewinnspielen;
        Nutzung im Zusammenhang mit Waffenherstellung oder Waffenhandel;
        Nutzung im Zusammenhang mit gewalttätigen oder pornographischen Inhalten;
        Nutzung im Zusammenhang mit esoterischen oder pseudoreligiösen Inhalten.</P>
        <P>c. Tätigkeiten, welche einer störungsfreien Nutzung des Vilhelm7 Coworking durch die Nutzer entgegenstehen, insbesondere:
          Behinderung oder Abhalten anderer Nutzer vom Zugang und von der Anwendung der Services und Infrastruktur der 2inSeven GmbH;
          Tätigkeiten, welche mit Lärm-, Geruchs- oder sonstiger Belästigung einhergehen; Angabe von falschen Identitätsdaten.
          Ein Verstoß gegen eine dieser Bestimmung berechtigt die 2inSeven GmbH zur fristlosen Kündigung.</P>
      <P>12.4 Nutzer des Monatspaketes sind berechtigt, eigene Einrichtungsgegenstände und technische Geräte nach vorheriger Abstimmung mit der 2inSeven GmbH in den Räumen aufzustellen.</P>
     <P>12.5 Die 2inSeven GmbH stellt den Nutzern sämtliche Gegenstände in einem mangelfreien Zustand zur Verfügung. Die Geräte werden regelmäßig getestet und gewartet. Mit allen zur Verfügung gestellten Geräten ist sorgfältig umzugehen. Jede missbräuchliche Nutzung ist untersagt. Jede Beschädigung wird dem Nutzer berechnet.</P>
      <P>12.6 Jegliche Veränderungen an den Arbeitsplätzen/offices, Um- und Einbauten, Installationen, etc. durch den Nutzer sind nur nach schriftlicher Genehmigung durch die 2inSeven GmbH und auf Kosten des Nutzers zulässig. Auf Verlangen der2inSeven GmbH ist der Nutzer zur völligen fachgerechten Wiederherstellung des Arbeitsplatzes/office spätestens bei Rückgabe verpflichtet. Im Falle der Zustimmung durch die 2inSeven GmbH zur Veränderung des Arbeitsplatzes/offices sind etwa erforderliche behördliche Genehmigungen, gleich welcher Art, durch den Nutzer einzuholen. Hierdurch entstehende Kosten trägt der Nutzer.</P>
      <P>12.7 Je nach Basispaket oder nach schriftlicher Vereinbarung erhält der Nutzer einen Schlüssel für den Eingang. Der Nutzer darf den Schlüssel nicht an Dritte weitergeben. Sollte der Nutzer den Schlüssel verlieren oder wenn Gefahr besteht, dass Dritte in Besitz des Schlüssels gelangt sind, ist der Nutzer verpflichtet dies der 2inSeven GmbH unverzüglich mitzuteilen. Ein Verstoß gegen diese Bestimmung berechtigt die 2inSeven GmbH zur fristlosen Kündigung. Etwaige Kosten eines Austausches der Schließanlage trägt der Nutzer.</P>
      <br/>
        <h2>13. Nutzungsverhalten im Internet</h2>
      <P>13.1 Der Nutzer verpflichtet sich, alle anwendbaren lokalen, nationalen und ggfs. internationalen Gesetze und Richtlinien zu respektieren und einzuhalten. Dies gilt auch für den Datenverkehr des Nutzers über die 2inSeven GmbH. Der Nutzer meldet Gesetzesverstöße an. Der Nutzer allein ist verantwortlich für alle seine Handlungen und Unterlassungen im Rahmen der Internetnutzung.</P>
      <P>13.2 Der Nutzer unterliegt bei der Abfrage, Speicherung, Übermittlung, Verbreitung und Darstellung bestimmter Inhalte gesetzlichen Beschränkungen. Dazu gehören insbesondere die urheberrechtlichen Beschränkungen. Das Kopieren, Verbreiten oder Herunterladen von urheberrechtlich geschützter Musik oder Bewegtbildern (Filmen) ist strengstens untersagt.</P>
      <P>13.3 Bei einer schuldhaften Verletzung dieser Verpflichtung durch den Nutzer hat dieser der 2inSeven GmbH den entstandenen Schaden zu ersetzen.</P>
     <br/>
     <h2>14. Gewährleistung, Haftung</h2>
        <P>14.1 Der Nutzer hat die Arbeitsplätze vor Vertragsschluss eingehend besichtigt. Er hat zur Kenntnis genommen, dass sich die Arbeitsplätze im Flurbereich um im Meeting-/Konferenzraum auf gemeinschaftlich genutzter Fläche befinden und mit Ausnahme der Offices nicht separat verschließbar sind. Er verzichtet wegen des ihm bekannten Zustandes auf etwaige Minderungsansprüche. Die 2inSeven GmbH übernimmt gegenüber dem Nutzer bei Übergabe und für die Dauer der Nutzung keine Garantie für den Zustand des jeweiligen Arbeitsplatzes. Der Nutzer erkennt an, dass sich der jeweils von ihm genutzte Arbeitsplatz einschließlich sämtlicher Einrichtungsgegenstände vor Nutzungsbeginn im vertragsgemäßen Zustand befindet.</P>
<P>14.2 Der Nutzer erklärt im Falle von Modernisierungs- und Instandsetzungsarbeiten die Duldung dieser Arbeiten und versichert, dass er aus eventuellen Beeinträchtigungen am Arbeitsplatz keine Minderungsrechte, bzw. Schadensersatzansprüche herleiten wird, sofern die 2inSeven GmbH diese nicht vorsätzlich oder grob fahrlässig verursacht hat.</P>
      <P>14.3 Die Haftung für Schäden, die durch Fahrlässigkeit durch die 2inSeven GmbH oder durch ihre gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht werden, ist ausgeschlossen. Die Haftung beschränkt sich auf solche Verletzungen, die grob fahrlässig oder vorsätzlich herbeigeführt werden. </P>
<P>Die Haftungsbeschränkung gilt nicht in Fällen der Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren Erfüllung der Nutzer vertraut hat und vertrauen durfte („vertragstypische Pflichten“) und bei Verletzungen, die bei Geschäften der vorliegenden Art typischerweise entstehen. In solchen Fällen ist die Haftung auf die vorhersehbaren, typischerweise eintretenden Schäden beschränkt. Unterhält die 2inSeven GmbH zur Regulierung eine eintrittspflichtige Versicherung, so beschränkt sich die Höhe des Ersatzes auf die Deckungssumme, die die Versicherung auszahlt.</P>
     <P>Der Haftungsausschluss gilt ferner nicht für Schadensersatzansprüche, aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer fahrlässigen Pflichtverletzung der 2inSeven GmbH oder einer vorsätzlichen oder fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen der 2inSeven GmbH beruhen sowie für sonstige Schäden, die auf einer grob fahrlässigen Pflichtverletzung der 2inSeven GmbH, auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen der 2inSeven GmbH beruhen.</P>
    <P>14.5 Die 2inSeven GmbH übernimmt keine Haftung für die Verletzung von Schutzrechten Dritter in Bezug auf Arbeiten der Nutzer, sowie die Übermittlung von Daten und Datenträgern durch den Nutzer. Der Nutzer ist dafür verantwortlich, dass alle wettbewerbsrechtlichen, urheberrechtlichen, markenrechtlichen, datenrechtlichen oder sonstige Rechtsverstöße im Rahmen der Vertragsbeziehung zur 2inSeven GmbH unterbleiben. Sofern die 2inSeven GmbH von derartigen Rechtsverstößen Kenntnis erhält, hat die 2inSeven GmbH das Recht, das Vertragsverhältnis unverzüglich zu kündigen. Im Falle eines Rechtsverstoßes hält der Nutzer die 2inSeven GmbH von jeglichen Ansprüchen Dritter frei. Der Nutzer ersetzt der 2inSeven GmbH die Kosten der Rechtsverfolgung in der Höhe der gesetzlichen Anwaltsgebühren für den Fall, dass die 2inSeven GmbH von Dritten infolge einer Rechtsverletzung in Anspruch genommen wird. Die Freistellungsverpflichtung gilt auch für Fälle der Verletzung von Pflichten nach 12.1 bis 12.3 und 13.</P>
      <P>14.6 Nach Ende der Nutzung hat der Kunde sämtliche, auch die von ihm selbst beschafften Schlüssel an die 2inSeven GmbH zurückzugeben. Kommt der Kunde dieser Verpflichtung nicht oder nicht rechtzeitig nach, so ist die 2inSeven GmbH berechtigt, die Arbeitsplätze auf Kosten des Nutzers zu öffnen und zu reinigen. Zurückgelassene Gegenstände kann die 2inSeven GmbH auf Kosten des Kunden einlagern oder entsorgen, wenn diese trotz Aufforderung nicht entfernt werden. Anlagen, Einrichtungen und Zubehör sind in gebrauchsfähigem Zustand zurückzugeben.</P>
      <P>14.8 Das Betreten der angrenzenden Hotelzimmer durch den Nutzer oder Besuch des Nutzers ist untersagt.</P>
      <P>14.9 Die Räumlichkeiten der 2inSeven GmbH sind nicht kindergerecht oder kindersicher eingerichtet. Es steht den Nutzern frei, eigene Kinder auf eigene Gefahr mitzubringen, sofern die Eltern selbst für die Sicherheit und das Wohlergehen Sorge tragen und das Kind einer störungsfreien Nutzung von Vilhelm7 Coworking nicht entgegensteht. Eltern haften für ihre Kinder. Auf die Gefahren insbesondere auf dem Hofspielplatz sowie bei den Steckdosen wurde explizit hingewiesen.</P>
      <P>14.10 Nutzer können Hunde nach vorheriger Absprache mit der  2inSeven GmbH mitbringen. Voraussetzung ist, dass der Hund die Nutzung von Vilhelm7 Coworking nicht beeinträchtigt (zum Beispiel durch mangelnde Stubenreinheit, Lärm- oder Geruchsbelästigung) und insbesondere ,dass der Hund sich gegenüber Menschen nicht aggressiv verhält. Die Zusage kann durch die 2inSeven GmbH im freien Ermessen entzogen werden, insbesondere dann, wenn sich andere Nutzer gestört fühlen. Der Halter sorgt dafür, dass der Hund keinen Kot auf dem Gelände oder den angrenzenden Straßen hinterlässt.</P>
      <P>14.11 Für die persönlichen Gegenstände der Nutzer und die Garderobe übernimmt die 2inSeven GmbH keine Haftung.</P>
      <P>14.12  Der 2inSeven GmbH steht für Forderungen gegen den Nutzer aus dem Vertrag ein Pfandrecht an den eingebrachten Sachen des Nutzers zu.</P>
   <br/>
   <h2>15. Versicherung</h2>
        <P>15. Es besteht kein Versicherungsschutz für persönliche Gegenstände der Nutzer.</P>
      <br/>
      <h2>16. Beendigung des Nutzungsverhältnisses</h2>
        <P>16.1 Der Nutzer hat die Gegenstände pfleglich zu behandeln und nach Beendigung der Nutzung in vertragsgemäßem, mangelfreiem und gebrauchsfähigem Zustand an die 2inSeven GmbH zurückzugeben. Schäden, die auf Veränderungen oder Verschlechterungen beruhen, die nicht auf den vertragsgemäßen Gebrauch zurückzuführen sind oder verlorene Einrichtungsgegenstände sind der 2inSeven GmbH vollumfänglich vom Nutzer zu ersetzen.</P>
    <P>16.2 Der Nutzer hat sämtliche an ihn ausgegebene Schlüssel an die 2inSeven GmbH zurückzugeben.</P>
     <br/>
        <h2>17. Änderung der AGB</h2>
        <P>Die 2inSeven GmbH behält sich vor, diese AGB jederzeit zu ändern. Die Änderung wird dem Nutzer umgehend mitgeteilt. Sofern der Nutzer der Änderung der AGB nicht innerhalb von 14 Tagen nach Zugang der Änderungsmitteilung schriftlich widerspricht, gelten die geänderten AGB als angenommen. Wird das Festhalten am Vertrag für den Nutzer aus diesem Grund unzumutbar, so steht ihm ein Sonderkündigungsrecht zu.</P>
      <br/>
      <h2>18. Inhalt</h2>
        <P>Die 2inSeven GmbH übernimmt keine Haftung für die Aktualität, die inhaltliche Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten Informationen und Materialien, es sei denn, die Fehler wurden vorsätzlich oder grob fahrlässig aufgenommen. Dies bezieht sich auf jegliche materielle und immaterielle Schäden, die durch die Nutzung der vom Kempinski AG auf ihrer Webseite bereitgestellten Informationen und Materialien verursacht wurden. Alle Angebote sind freibleibend und unverbindlich.</P>
      <br/>
      <h2>19. Verweise und Links</h2>
        <P>Die 2inSeven GmbH hat keinen Einfluss auf die Gestaltung und die Inhalte fremder Materialien, auf die von ihrer Webseite aus direkt oder indirekt verwiesen wird. Daher distanziert sich das 2inSeven GmbH ausdrücklich von solchen Materialien und lehnt hierfür eine Haftung ab. Dies gilt nicht, sofern das 2inSeven GmbH Kenntnis von rechtwidrigen Inhalten fremder Webseiten hat und es ihr technisch möglich und zumutbar ist, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.</P>
      <br/>
      <h2>20. Copyright</h2>
        <P>Das Layout der Webseite, der benutzten Diagramme, Bilder und Logos sowie die Sammlung einzelner Beiträge sind urheberrechtlich geschützt. Eine Vervielfältigung oder Nutzung von Objekten, wie z.B. Diagrammen, Bildern oder Texten, in anderen elektronischen oder gedruckten Veröffentlichungen ist ohne vorherige Zustimmung der 2inSeven GmbH nicht erlaubt.</P>
      <br/>
      <h2>21. Datenschutzbestimmungen</h2>
        <P>Um unsere Datenschutzerklärung zu sehen, klicken Sie bitte <b>hier</b>. </P>
        <br/>
        <h2>22. Schlussbestimmungen</h2>
        <P>22.1 Mündliche Nebenabreden bestehen nicht. Alle Nebenabreden bedürfen der Schriftform.</P>
      <P>22.2 Für die Rechtsbeziehungen im Zusammenhang mit dem Vertrag gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).</P>
      <P>22.3 Sofern es sich beim Nutzer um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder öffentlich rechtliches Sondervermögen handelt, ist ausschließlicher Gerichtsstand Berlin. Die 2inSeven GmbH ist auch berechtigt, am allgemeinen Gerichtsstand des Nutzers zu klagen.</P>
      <P>22.4 Sollten einzelne Bestimmungen ganz oder teilweise unwirksam sein oder ihre Rechtswirksamkeit später verlieren, so soll hierdurch die Gültigkeit der übrigen Klauseln nicht berührt werden. Für diesen Fall verpflichten sich die Parteien an Stelle der unwirksamen Regelung eine wirksame Regelung zu vereinbaren, die, soweit rechtlich möglich, den in diesen Geschäftsbedingungen zum Ausdruck gekommenen Interessen der Parteien am nächsten kommt. Das gleiche gilt für den Fall, dass eventuelle Ergänzungen notwendig werden.</P>
     <P>Stand der AGB: März 2021</P>
      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Agb
